<template>
  <div class="filters">
    <div class="flex__between">
      <h2>Filter and Sort</h2>
      <span class="breadcrumb" @click="reset">Reset</span>
    </div>
    <div class="flex__between p-tb-sm">
      <button class="btn btn__sm btn__red" @click="emitToggleFilters">Exit</button>
      <button class="btn btn__sm btn__frost" @click="submit">Submit</button>
    </div>
    <simplebar class="filters--overflow">
      <div class="filters--form" @keypress.enter="submit">
        <label>
          Requester Name
          <input
            type="text"
            v-model="form.requesterName"
            placeholder="first and/or last"
          />
        </label>
        <label>
          Date Start
          <input
            type="date"
            v-model="form.dateStart"
            v-mask="'####-##-##'"
            placeholder="YYYY-MM-DD"
          />
        </label>
        <label>
          Date End
          <input
            type="date"
            v-model="form.dateEnd"
            v-mask="'####-##-##'"
            placeholder="YYYY-MM-DD"
          />
        </label>
        <label>
          Is Favorite
          <select v-model="form.isFavorite">
            <option value>Any</option>
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>
        </label>
        <div class="permissions--select flex__between pointer" @click="toggleStatus">
          <span>Order Status</span>
          <span>
            <font-awesome-icon v-if="!statusToggled" icon="caret-down" size="sm" class="ml-10" />
            <font-awesome-icon v-else icon="caret-up" size="sm" class="ml-10" />
          </span>
        </div>
        <ul v-if="statusToggled" class="permissions--list">
          <li v-for="option in statusOptions" :key="option">
            <label>
              <input type="checkbox" :value="option" v-model="form.status" />
              {{ option }}
            </label>
          </li>
        </ul>
        <label>
          Order Type
          <select v-model="form.orderType">
            <option value>Any</option>
            <option value="standard">Standard</option>
            <option value="bulk">Batch</option>
          </select>
        </label>
        <label>
          Recipient Type
          <select v-model="form.recipientType">
            <option value>Any</option>
            <option value="Borrower">Borrower</option>
            <option value="Referral Partner">Referral Partner</option>
            <option value="Internal Employee">Internal Employee</option>
          </select>
        </label>
        <div class="permissions--select flex__between pointer" @click="toggleProducts">
          <span>Order Product</span>
          <span>
            <font-awesome-icon v-if="!productsToggled" icon="caret-down" size="sm" class="ml-10" />
            <font-awesome-icon v-else icon="caret-up" size="sm" class="ml-10" />
          </span>
        </div>
        <input v-if="productsToggled" type="text" v-model="search" placeholder="product search" />
        <simplebar class="major--overflow" data-simplebar-auto-hide="false">
          <ul v-if="productsToggled" class="permissions--list">
            <li v-for="product in filteredProducts" :key="product._id">
              <label>
                <input type="checkbox" :value="product._id" v-model="form.products" />
                {{ product.name.slice(0,15) }}...
              </label>
            </li>
          </ul>
        </simplebar>
        <label>
          Recipient Name
          <input
            type="text"
            v-model="form.recipientName"
            placeholder="first and/or last"
          />
        </label>
        <label>
          Billing Type
          <select v-model="form.billingType">
            <option value>Any</option>
            <option value="Payroll">Payroll Deduction</option>
            <option value="Marketing Budget">Marketing Budget</option>
          </select>
        </label>
        <!-- <label>
          Loan Number
          <input type="text" v-mask="'##########'" v-model="form.loanNumber" />
        </label> -->
        <label>
          Date Sort
          <select v-model="form.dateSort">
            <option value="newest-to-oldest">Newest-to-Oldest</option>
            <option value="oldest-to-newest">Oldest-to-Newest</option>
          </select>
        </label>
      </div>
    </simplebar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["orderFilters"],
  data() {
    return {
      statusToggled: false,
      productsToggled: false,
      statusOptions: [
        "Started",
        "Submitted",
        "Ordered",
        "In Progress",
        "Awaiting Input",
        "Ready to Ship",
        "Sent",
        "Beer",
        "Pre-Order",
        "Complete",
        "Admin Complete"
      ],
      search: "",
      form: {
        requesterName: this.orderFilters ? this.orderFilters.requesterName : "",
        dateStart: this.orderFilters ? this.orderFilters.dateStart : "",
        dateEnd: this.orderFilters ? this.orderFilters.dateEnd : "",
        isFavorite: this.orderFilters ? this.orderFilters.isFavorite : "",
        status: this.orderFilters ? this.orderFilters.status : [],
        orderType: this.orderFilters ? this.orderFilters.orderType : "",
        products: this.orderFilters ? this.orderFilters.products : [],
        recipientType: this.orderFilters ? this.orderFilters.recipientType : "",
        recipientName: this.orderFilters ? this.orderFilters.recipientName : "",
        billingType: this.orderFilters ? this.orderFilters.billingType : "",
        loanNumber: this.orderFilters ? this.orderFilters.loanNumber : "",
        dateSort: this.orderFilters
          ? this.orderFilters.dateSort
          : "newest-to-oldest"
      }
    };
  },
  watch: {
    orderFilters: {
      handler: function(newVal) {
        this.form = JSON.parse(JSON.stringify(newVal));
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(["getProducts"]),
    filteredProducts() {
      if (!this.search) return this.getProducts;
      return this.getProducts.filter(product => {
        return product.name.toLowerCase().includes(this.search.toLowerCase());
      });
    }
  },
  methods: {
    ...mapActions(["startSetOrderFilters", "startUnsetOrderFilters"]),
    toggleStatus() {
      this.statusToggled = !this.statusToggled;
    },
    toggleProducts() {
      this.productsToggled = !this.productsToggled;
    },
    emitToggleFilters() {
      this.$emit("toggleFilters", false);
    },
    submit() {
      this.startSetOrderFilters(this.form);
      this.$emit("resetPage");
      this.emitToggleFilters();
    },
    reset() {
      this.startUnsetOrderFilters();
      this.$emit("resetPage");
      this.emitToggleFilters();
    },
    setRequesterEmail() {
      if (this.$route.query.name) {
        this.form.requesterName = this.$route.query.name;
      }
    },
    setOrderStatus() {
      let custOptions = ["incomplete", "progress", "ready", "pre-order"];
      if (
        this.$route.query.status &&
        this.statusOptions.includes(this.$route.query.status)
      ) {
        return this.form.status.push(this.$route.query.status);
      }
      if (
        this.$route.query.status &&
        custOptions.includes(this.$route.query.status)
      ) {
        let statuses;
        if (this.$route.query.status === "incomplete") statuses = ["Submitted"];
        if (this.$route.query.status === "progress")
          statuses = [
            "Ordered",
            "In Progress",
            "Awaiting Input",
            "Ready to Ship",
            "Sent"
          ];
        if (this.$route.query.status === "ready") statuses = ["Ready to Ship"];
        if (this.$route.query.status === "pre-order") statuses = ["Pre-Order"];
        return this.form.status.push(...statuses);
      }
    },
    setOrderType() {
      const options = ["bulk", "standard"];
      if (
        this.$route.query.ordertype &&
        options.includes(this.$route.query.ordertype)
      ) {
        this.form.orderType = this.$route.query.ordertype;
      }
    },
    setDateStart() {
      if (this.$route.query.datestart) {
        this.form.dateStart = this.$route.query.datestart;
      }
    },
    setIsFavorite() {
      if (this.$route.query.favorite) {
        this.form.isFavorite = this.$route.query.favorite === "true";
      }
    },
    shouldReset() {
      if (this.$route.query.reset) {
        let query = Object.assign({}, this.$route.query);
        delete query.reset;
        this.$router.replace({ query });
        const filters = {
          requesterName: "",
          dateStart: "",
          dateEnd: "",
          isFavorite: "",
          status: [],
          orderType: "",
          products: [],
          recipientType: "",
          recipientName: "",
          billingType: "",
          loanNumber: "",
          includesProduct: [],
          dateSort: "newest-to-oldest"
        };
        for (let key in filters) {
          this.form[key] = filters[key];
        }
      }
    }
  },
  mounted() {
    // submit form after filters collected
    this.shouldReset();
    this.setRequesterEmail();
    this.setOrderStatus();
    this.setOrderType();
    this.setDateStart();
    this.setIsFavorite();
    this.startSetOrderFilters(this.form);
  }
};
</script>

<style scoped lang="scss">
.permissions--select {
  margin-top: 5px;
  padding: 10px;
  border: 1px solid $black;
  border-radius: 5px;
  background-color: $frost;
}
.permissions--list {
  padding: 10px;
  @include grid($cols: 1fr, $row-gap: 10px);
}
.permissions--list li label {
  @include grid($cols: 20px 1fr, $col-gap: 15px, $align: center);
}
.major--overflow {
  max-height: 150px;
}
@media (min-width: $lg) {
  .btn__red {
    display: none;
  }
}
</style>