<template>
  <section class="flex__between">
    <div class="header--sort flex__center pointer shadow" @click="emitToggleFilters">
      <font-awesome-icon icon="filter" size="lg" class="icon" />
      <p class="flex__col ml-10">
        <span>Sort &</span>
        <span>Filter</span>
      </p>
    </div>
    <div class="header--reset flex__center pointer shadow" @click="emitClearFilters">
      <font-awesome-icon icon="eraser" size="lg" class="icon" />
      <p class="flex__col ml-10">
        <span>Clear</span>
        <span>Filters</span>
      </p>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    emitToggleFilters() {
      this.$emit("toggleFilters", true);
    },
    emitClearFilters() {
      this.$emit("clearFilters", true);
    }
  }
};
</script>

<style scoped lang="scss">
.header--sort,
.header--reset {
  border-radius: 5px;
  padding: 15px 5px;
  background-color: #fff;
}
.icon {
  color: $peacock;
}
p {
  line-height: $text-regular;
  font-size: $text-regular;
}
</style>