<template>
  <div class="flex__between margin__md">
    <div class="flex__between flex__wrap">
      <p class="text__center mr-10">Page</p>
      <p class="text__center">{{ currentPage }} of {{ pages }}</p>
      <p v-if="itemCnt" class="ml-10 mr-10 text__sm">({{ itemCnt }} items)</p>
    </div>
    <div class="products--pages--arrows flex__center">
      <div
        class="box flex__center pointer"
        v-for="(icon, index) in icons"
        :key="icon"
        @click="emitChangePage(index)"
      >
        <font-awesome-icon :icon="icon" size="1x" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["pages", "current", "itemCnt"],
  data() {
    return {
      icons: [
        "angle-double-left",
        "angle-left",
        "angle-right",
        "angle-double-right"
      ]
    };
  },
  computed: {
    currentPage() {
      return this.current + 1;
    }
  },
  methods: {
    emitChangePage(index) {
      this.$emit("changePage", index);
    }
  }
};
</script>

<style scoped lang="scss">
.box {
  width: 35px;
  height: 35px;
  background-color: $dark-blue;
  color: #fff;
  margin: 0px 10px;
  border-radius: 3px;
  &:hover {
    background-color: $peacock;
  }
}
</style>