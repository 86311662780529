<template>
  <ul class="past-orders">
    <Order v-for="order in orders" :key="order._id" :order="order" />
  </ul>
</template>

<script>
import Order from "./Order";
export default {
  props: ["orders"],
  components: {
    Order
  }
};
</script>

<style scoped lang="scss">
.past-orders {
  @include grid($cols: 1fr, $col-gap: 25px, $row-gap: 25px);
  height: fit-content;
}
@media (min-width: $lg) {
  .past-orders {
    grid-template-columns: 1fr 1fr;
  }
}
</style>