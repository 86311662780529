<template>
  <section class="past-header">
    <div class="container">
      <div class="flex__between">
        <router-link :to="isAdmin ? '/admin' : '/my-account'" class="breadcrumb">
          <font-awesome-icon icon="arrow-left" size="sm" class="mr-10" />
          {{ isAdmin ? 'Back to Admin' : 'My Account' }}
        </router-link>
        <button class="btn btn__sm btn__frost mt-15" @click="emitToggleModal">Export</button>
      </div>
      <h1 class="text__center padding__sm">{{ isAdmin ? 'All Orders' : "Your Orders" }}</h1>
      <p
        class="header--message"
      >These past orders can be changed prior to the milestone reaching "In Progress". To communicate with WOW about a specific order please respond to the email that was sent out when you submitted.</p>
      <div class="past-header--filter">
        <FilterButton @toggleFilters="emitToggleFilters" class="past-header--sort small--filters" />
      </div>
    </div>
  </section>
</template>

<script>
import FilterButton from "../../FilterButton";
export default {
  components: {
    FilterButton
  },
  computed: {
    isAdmin() {
      const route = this.$route.fullPath;
      return route.includes("/admin");
    }
  },
  methods: {
    emitToggleFilters() {
      this.$emit("toggleFilters", true);
    },
    emitToggleModal() {
      this.$emit("toggleModal");
    }
  }
};
</script>

<style scoped lang="scss">
.past-header--filter {
  margin-top: 15px;
}
</style>