<template>
  <section class="modal flex__center" @click.self="emitToggleModal">
    <div class="tile orders-export">
      <exit-button @toggleModal="emitToggleModal" />
      <p class="text__bold text__center text__md">Export Order Data</p>
      <p class="text__center">Click a button below to export the requested data</p>
      <download-excel
        :data="allData"
        name="all-data.xls"
        type="csv"
        class="btn btn__sm btn__green mt-15"
      >All Data</download-excel>
      <download-excel
        :data="allOrders"
        name="order-data.xls"
        type="csv"
        class="mt-15 btn btn__sm btn__frost"
      >Order Data Only</download-excel>
      <download-excel
        :data="allOrderItems"
        name="order-item-data.xls"
        type="csv"
        class="mt-15 btn btn__sm btn__frost"
      >Order Items Data Only</download-excel>
    </div>
  </section>
</template>

<script>
export default {
  props: ["orders"],
  computed: {
    allData() {
      const copyData = JSON.parse(JSON.stringify(this.orders));
      const orders = copyData
        .map(order => {
          return order.orderItems.map(item => {
            const custs = item.customizations
              .map(cust => {
                return `${cust.name}: ${cust.option}`;
              })
              .join("\n");
            const address = `${order.shippingAddress.street}\n${order.shippingAddress.city}, ${order.shippingAddress.state}\n${order.shippingAddress.zip}`;
            return {
              order_id: order._id,
              requester_name: order.requesterName,
              requester_email: order.requesterEmail,
              requester_2Email: order.requester2Email,
              recipient_type: order.recipientType,
              loan_number: order.loanNumber,
              recipient_name: order.recipientName,
              // co_recipient_name: order.coRecipientName,
              shipping_name: order.shippingName,
              address: address,
              shipping_phone: order.shippingPhone,
              milestone: order.milestone,
              billing_method: order.billingMethod,
              billing_name: order.billingName,
              total_cost: order.totalCost,
              balance_due: order.balanceDue,
              order_item_id: item._id,
              product_name: item.productName,
              inventoried: item.isInventoried,
              cost: item.loCost,
              quantity: item.quantity,
              customization: custs,
              item_status: item.status,
              thank_you_note: order.thankYouNote,
              comments: order.comments,
              delivery_date: order.deliveryDate,
              order_status: order.status
            };
          });
        })
        .flat();
      return orders;
    },
    allOrders() {
      const copyData = JSON.parse(JSON.stringify(this.orders));
      const orders = copyData.map(order => {
        const address = `${order.shippingAddress.street}\n${order.shippingAddress.city}, ${order.shippingAddress.state}\n${order.shippingAddress.zip}`;
        return {
          order_id: order._id,
          requester_name: order.requesterName,
          requester_email: order.requesterEmail,
          requester_2Email: order.requester2Email,
          recipient_type: order.recipientType,
          loan_number: order.loanNumber,
          recipient_name: order.recipientName,
          // co_recipient_name: order.coRecipientName,
          shipping_name: order.shippingName,
          address: address,
          shipping_phone: order.shippingPhone,
          milestone: order.milestone,
          billing_method: order.billingMethod,
          billing_name: order.billingName,
          total_cost: order.totalCost,
          balance_due: order.balanceDue,
          order_items_count: order.orderItems.length,
          thank_you_note: order.thankYouNote,
          comments: order.comments,
          delivery_date: order.deliveryDate,
          order_status: order.status
        };
      });
      return orders;
    },
    allOrderItems() {
      const copyData = JSON.parse(JSON.stringify(this.orders));
      const orders = copyData
        .map(order => {
          return order.orderItems.map(item => {
            const custs = item.customizations
              .map(cust => {
                return `${cust.name}: ${cust.option}`;
              })
              .join("\n");
            return {
              requester_name: order.requesterName,
              requester_email: order.requesterEmail,
              requester_2Email: order.requester2Email,
              recipient_name: order.recipientName,
              billing_method: order.billingMethod,
              billing_name: order.billingName,
              order_item_id: item._id,
              product_name: item.productName,
              inventoried: item.isInventoried,
              cost: item.loCost,
              quantity: item.quantity,
              customization: custs,
              order_thank_you_note: order.thankYouNote,
              item_status: item.status,
              order_status: order.status
            };
          });
        })
        .flat();
      return orders;
    }
  },
  methods: {
    emitToggleModal() {
      this.$emit("toggleModal");
    }
  }
};
</script>

<style scoped lang="scss">
.orders-export {
  width: 80%;
  max-width: 325px;
  position: relative;
  @include grid($cols: 1fr, $row-gap: 10px);
}
</style>