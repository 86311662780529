<template>
  <li class="order-tile tile pos__rel">
    <div class="order-tile--status flex__center" :class="statusClass">
      <span class="text__bold">{{ order.status }}</span>
    </div>
    <div class="order-row">
      <span>Created:</span>
      <span>{{ cleanDate }}</span>
    </div>
    <div class="order-row">
      <span>Requester:</span>
      <span class="text__overflow">{{ order.requesterName }}</span>
    </div>
    <div class="order-row">
      <span>Order Type:</span>
      <span>{{ order.bulkOrder ? 'Batch' : 'Normal' }}</span>
    </div>
    <div class="order-row">
      <span>Recipient:</span>
      <span class="text__overflow">{{ order.recipientName }}</span>
    </div>
    <div class="order-row">
      <span>Total Cost:</span>
      <span class="text__pine text__bold">${{ order.totalCost.toFixed(2) }}</span>
    </div>
    <ul>
      <simplebar class="small--overflow" data-simplebar-auto-hide="false">
        <li v-for="orderItem in order.orderItems" :key="orderItem._id" class="flex__between">
          <span>{{ orderItem.quantity }} - {{ orderItem.productName }}</span>
          <span>${{ (orderItem.quantity * orderItem.loCost).toFixed(2) }}</span>
        </li>
      </simplebar>
    </ul>
    <div class="order-row">
      <span>Balance Due:</span>
      <span class="text__green text__bold">${{ order.balanceDue.toFixed(2) }}</span>
    </div>
    <div class="order-comments">
      <span class="text__bold">Comments:</span>
      <simplebar class="small--overflow" data-simplebar-auto-hide="false">
        <span class="pre-wrap">{{ order.comments }}</span>
      </simplebar>
    </div>
    <div class="flex__between flex__reverse text__bold">
      <router-link :to="orderLink" class="link__none">
        View
        <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
      </router-link>
      <router-link
        v-if="order.isFavorite"
        :to="`/new-order/order-details?id=${order._id}`"
        class="btn btn__sm btn__green"
      >
        <font-awesome-icon icon="heart" size="lg" class="mr-10 icon" />Order
      </router-link>
    </div>
  </li>
</template>

<script>
import moment from "moment";
export default {
  props: ["order"],
  computed: {
    orderReference() {
      return this.order.status === "Started" ? "new-order" : "past-order";
    },
    cleanDate() {
      return moment(this.order.createdAt).format("MM-DD-YYYY hh:mm a");
    },
    orderLink() {
      return this.order.bulkOrder && this.order.status === "Started"
        ? `/batch-order/${this.order.bulkOrder}/batch-items`
        : `/${this.orderReference}/${this.order._id}/order-confirm`;
    },
    statusClass() {
      if (this.order.status === "Submitted") {
        return "order-tile--status__yellow";
      }
      if (this.order.status === "Complete") {
        return "order-tile--status__pine";
      }
      return null;
    }
  }
};
</script>

<style scoped lang="scss">
.order-tile {
  background-color: lighten($frost, 5%);
  height: 100%;
  transition: all 0.25s ease-in-out;
}
.order-tile:hover {
  background-color: lighten($frost, 10%);
  @include shadow;
}
.icon {
  color: $pine;
}
.btn:hover {
  .icon {
    color: $pistachio;
    animation: pump 2s infinite;
  }
}
@keyframes pump {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>