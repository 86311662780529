<template>
  <section class="past">
    <Header @toggleFilters="toggleFilters" @toggleModal="toggleModal" />
    <Export v-if="modalToggled" @toggleModal="toggleModal" :orders="getFilteredOrders" />
    <span ref="orderstop"></span>
    <div class="container">
      <Pagination
        :pages="pageCount"
        :current="firstSlice"
        :itemCnt="displayedOrders.length"
        @changePage="changePage"
      />
    </div>
    <div class="margin__md">
      <div class="past__grid container">
        <transition name="filter-slide">
          <FiltersForm
            @toggleFilters="toggleFilters"
            @resetPage="resetPage"
            v-if="filtersToggled"
            class="small--filters"
            :orderFilters="getOrderFilters"
          />
        </transition>
        <FiltersForm class="large--filters" @resetPage="resetPage" :orderFilters="getOrderFilters" />
        <Orders :orders="displayedOrders" />
      </div>
      <div class="container">
        <Pagination :pages="pageCount" :current="firstSlice" @changePage="changePage" />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./Header";
import Export from "./Export";
import Orders from "./Orders";
import FiltersForm from "./FiltersForm";
import Pagination from "../../Pagination";
export default {
  components: {
    Header,
    Export,
    Orders,
    FiltersForm,
    Pagination
  },
  metaInfo: {
    title: "Past Orders | WOW"
  },
  data() {
    return {
      filtersToggled: false,
      modalToggled: false,
      displayOrders: 16,
      firstSlice: 0
    };
  },
  computed: {
    ...mapGetters(["getFilteredOrders", "getOrderFilters"]),
    route() {
      return this.$route.fullPath;
    },
    orderCount() {
      return this.getFilteredOrders.length;
    },
    pageCount() {
      return Math.ceil(this.orderCount / this.displayOrders);
    },
    displayedOrders() {
      // pass these into products component
      return this.getFilteredOrders.slice(
        this.firstSlice * this.displayOrders,
        this.firstSlice * this.displayOrders + this.displayOrders
      );
    }
  },
  methods: {
    toggleFilters() {
      this.filtersToggled = !this.filtersToggled;
    },
    toggleModal() {
      this.modalToggled = !this.modalToggled;
    },
    changePage(index) {
      if (index === 0) {
        this.firstSlice = 0;
      }
      if (index === 1) {
        if (this.firstSlice === 0) {
          return;
        }
        this.firstSlice -= 1;
      }
      if (index === 2) {
        if (this.firstSlice + 1 === this.pageCount) {
          return;
        }
        this.firstSlice += 1;
      }
      if (index === 3) {
        this.firstSlice = this.pageCount - 1;
      }
      this.addRouteParams();
      this.scrollToTop();
    },
    scrollToTop() {
      this.$refs.orderstop.scrollIntoView({
        block: "nearest",
        behavior: "smooth"
      });
    },
    addRouteParams() {
      let queryChar = this.$route.fullPath.includes("&page=") ? "&" : "?";
      if (this.$route.fullPath.includes("page=")) {
        this.$router.push(
          `${this.$route.fullPath.replace(
            /page=\d/,
            `${queryChar}page=${this.firstSlice + 1}`
          )}`
        );
      } else {
        this.$router.push(
          `${this.$route.fullPath}${queryChar}page=${this.firstSlice + 1}`
        );
      }
    },
    resetPage() {
      this.firstSlice = 0;
      if (this.$route.query.page) return this.$router.replace({ query: null });
    }
  }
};
</script>

<style scoped lang="scss">
@media (min-width: $md) {
  .past__grid {
    @include grid($cols: 1fr 3fr, $col-gap: 25px);
  }
}
</style>